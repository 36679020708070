import React, { useEffect, useMemo, useState } from "react";
import { Button, Dialog, IconButton } from "@mui/material";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { addDoc, collection, deleteDoc, doc, DocumentData, getFirestore, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import firebaseClient from "../../services/firebaseClient";
import { DataGrid, GridColDef, GridRowParams, GridSelectionModel, GridValueGetterParams } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import AddEditComponent from "./AddEditComponent";
import { deleteUser, getAuth } from "firebase/auth";
import { tableTranslations } from "../../misc/localizations";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { EventResponse } from "../DogodkiPage/NotificationComponent";
import firebaseService from "../../services/firebaseService";
import { orderBy, query, where } from 'firebase/firestore'
import NewNotificationDialogComponent from "./NewNotificationDialogComponent";

const auth = getAuth(firebaseClient);

export default function OdziviNaDogodkePage(props: any) {
	let { eventId } = useParams();
	const [selectedResponseIdsInTable, setSelectedResponseIdsInTable] = useState<String[]>([]);

	// auth check
	const navigate = useNavigate();
	const [user, userLoading, userError] = useAuthState(auth);
	if (!user) navigate("/prijava");

	const [sendNotificationDialogVisible, setSendNotificationDialogVisible] = useState(false);

	const [eventDoc] = useDocument(doc(getFirestore(firebaseClient), "events", eventId ?? '0'));

	const [eventResponseDocs, eventResponsesLoading, eventResponsesError] = useCollection(
		query(
			collection(getFirestore(firebaseClient), 'event_responses'),
			where('event_id', '==', eventId ?? '0')
		)
	);
	const [users] = useCollection(collection(getFirestore(firebaseClient), "profiles"));
	const [addVisible, setAddVisible] = useState(false);
	const [sendSmsEventId, setSendSmsEventId] = useState<null | string>(null);

	const rows: EventResponse[] = useMemo(() => {
		if (!eventResponseDocs || eventResponsesError) return [];

		return eventResponseDocs?.docs.map((doc) => {
			const eventDataResponse = doc.data();

			let user;
			users?.docs.map((doc) => {
				const dataUser = doc.data();
				if (doc.id == eventDataResponse.user_id) {
					user = dataUser;
				}
			});

			return {
				id: doc.id,
				event_id: eventDoc ? eventDoc.id : "/",
				user_id: user ? user!.name + ' (' + user!.phone_number + ')' : "/",
				user_response: eventDataResponse.user_response != null ? eventDataResponse.user_response ? "DA" : "NE" : '/',
				selected: eventDataResponse.selected != null ? eventDataResponse.selected ? "IZBRAN" : "/" : '/',
			} as EventResponse;
		});
	}, [eventResponseDocs]);

	const columns: GridColDef[] = [
		{ field: "user_id", headerName: "Uporabnik", minWidth: 150, flex: 1 },
		{ field: "user_response", headerName: "Odziv", minWidth: 150, flex: 1 },
		{ field: "selected", headerName: "Izbira", minWidth: 150, flex: 1 },
		{
			field: "options",
			headerName: "Možnosti",
			maxWidth: 180,
			flex: 1,
			renderCell: (params: any) => {
				return (
					<>
						<IconButton aria-label="delete" size="small" onClick={() => handleDeleteClick(params.id)}>
							<DeleteIcon fontSize="inherit" />
						</IconButton>
					</>
				);
			},
		},
	];

	const handleDeleteClick = async (id: string) => {
		const shouldDelete = window.confirm("Ali ste prepričani, da želite izbrisati odziv?");

		if (shouldDelete) {
			const res = await deleteDoc(doc(getFirestore(firebaseClient), "event_responses", id));
		}
	};

	const setSelectedResponses = async (val: GridSelectionModel) => {
		setSelectedResponseIdsInTable(val as String[]);
	}

	const updateSelectedResponses = async () => {
		// update without using batch
		selectedResponseIdsInTable.forEach(async (id) => {
			await updateDoc(doc(getFirestore(firebaseClient), "event_responses", id as string), {
				selected: true,
			});
		});
	}

	const [notificationToSelectedOnly, setNotificationToSelectedOnly] = useState<boolean>();

	const selectedUsers = useMemo(() => {
		var _data: DocumentData[] = [];
		eventResponseDocs?.docs.forEach((resDoc) => {
			var resData = resDoc.data();

			users?.docs.forEach((userDoc) => {
				var userData = userDoc.data();

				userData.id = userDoc.id;

				if (resData.user_id == userDoc.id && resData.selected == true) {

					_data.push(userData);
				}
			});
		});
		return _data;
	}, [notificationToSelectedOnly, eventResponseDocs, users])

	const unselectedUsers = useMemo(() => {
		var _data: DocumentData[] = [];
		eventResponseDocs?.docs.forEach((resDoc) => {
			var resData = resDoc.data();

			users?.docs.forEach((userDoc) => {
				var userData = userDoc.data();
				userData.id = userDoc.id;

				if (resData.user_id == userDoc.id && (resData.selected == false || resData.selected == undefined)) {
					_data.push(userData);
				}
			});
		});
		return _data;
	}, [notificationToSelectedOnly, eventResponseDocs, users])

	const handleSendNotificationToSelectedClick = (toSelected: boolean) => {
		setNotificationToSelectedOnly(toSelected);
		setSendNotificationDialogVisible(true);
	}

	return (
		<div className="h-[80vh] w-[1400px] max-w-full p-4 m-auto">
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
				<div>
					<h1 className="mb-6 text-3xl">
						Odzivi na dogodek
					</h1>
					<h2>{eventDoc?.data()?.name ?? '/'}</h2>
					<p style={{ marginBottom: '16px' }}>{eventDoc?.data()?.start_timestamp?.toDate()?.toLocaleString() ?? '/'} - {eventDoc?.data()?.end_timestamp?.toDate()?.toLocaleString() ?? '/'}</p>
				</div>
				<div style={{ textAlign: 'right', display: 'flex', gap: '16px', marginBottom: '16px' }}>
					<Button variant="outlined" size="small" onClick={updateSelectedResponses} disabled={selectedResponseIdsInTable.length <= 0}>Izberi za dogodek</Button>
					<Button variant="outlined" size="small" onClick={() => handleSendNotificationToSelectedClick(true)} disabled={selectedUsers.length <= 0}>Pošlji obvestilo vsem IZBRANIM</Button>
					<Button variant="outlined" size="small" onClick={() => handleSendNotificationToSelectedClick(false)} disabled={unselectedUsers.length <= 0}>Pošlji obvestilo vsem NEIZBRANIM</Button>
				</div>
			</div>
			{rows && <DataGrid 
						localeText={tableTranslations} 
						rows={rows} columns={columns} 
						pageSize={15} 
						rowsPerPageOptions={[5]} 
						checkboxSelection onSelectionModelChange={(e) => setSelectedResponses(e)} 
						isRowSelectable={(params: GridRowParams) => params.row.user_response == 'DA' && params.row.selected != 'IZBRAN'} 
					/>}
			<Dialog open={sendNotificationDialogVisible} onClose={() => setSendNotificationDialogVisible(false)}>
				<NewNotificationDialogComponent eventId={eventDoc?.id ?? '0'} users={notificationToSelectedOnly ? selectedUsers : unselectedUsers} onClose={() => setSendNotificationDialogVisible(false)} />
			</Dialog>
		</div>
	);
}
