import React, { useEffect, useMemo, useState } from "react";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useCollection } from "react-firebase-hooks/firestore";
import { addDoc, collection, deleteDoc, doc, getFirestore, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import firebaseClient from "../../services/firebaseClient";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { deleteUser, getAuth } from "firebase/auth";
import { tableTranslations } from "../../misc/localizations";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

const auth = getAuth(firebaseClient);

export default function RazpolozljivostPage() {
  // auth check
  const navigate = useNavigate();
  const [user, userLoading, userError] = useAuthState(auth);
  if (!user) navigate("/prijava");

  const [users] = useCollection(collection(getFirestore(firebaseClient), "profiles"));

  const [value, loading, error] = useCollection(collection(getFirestore(firebaseClient), "availability"));

  const rows: Razpolozljivost[] = useMemo(() => {
    if (!value || error) return [];

    // filter out availabilities where 'to' timestmap is in the past
    const filtered = value.docs.filter((doc) => {
      const data = doc.data();
      return data.to.toDate() > new Date();
    });

    return filtered.map((doc) => {
      const data = doc.data();

      let user;
			users?.docs.map((docUser) => {
				const dataUser = docUser.data();
				if (dataUser.phone_number == data.user) {
					user = dataUser;
				}
			});

      return {
        id: doc.id,
        user: `${user ? user!.name : ''} (${data.user})`,
        from: data.from,
        to: data.to,
      } as Razpolozljivost;
    });
  }, [value]);

  const columns: GridColDef[] = [
    { field: "user", headerName: "Uporabnik", minWidth: 150, flex: 1 },
    {
      field: "from",
      headerName: "Od",
      minWidth: 160,
      flex: 1,
      renderCell: (params: any) => {
        return <p>{params?.value?.toDate()?.toLocaleString() ?? "/"}</p>;
      },
    },
    {
      field: "to",
      headerName: "Do",
      minWidth: 160,
      flex: 1,
      renderCell: (params: any) => {
        return <p>{params?.value?.toDate()?.toLocaleString() ?? "/"}</p>;
      },
    },
    {
      field: "options",
      headerName: "Možnosti",
      maxWidth: 90,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton aria-label="delete" size="small" onClick={() => handleDeleteClick(params.id)}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </>
        );
      },
    },
  ];

  const handleDeleteClick = async (id: string) => {
    const shouldDelete = window.confirm("Ali ste prepričani, da želite izbrisati razpoložljivost?");

    if (shouldDelete) {
      const res = await deleteDoc(doc(getFirestore(firebaseClient), "availability", id));
    }
  };

  return (
    <div className="h-[80vh] w-[1400px] max-w-full p-4 m-auto">
      <h1 className="mb-6 text-3xl">Razpoložljivost</h1>
      {rows && <DataGrid localeText={tableTranslations} rows={rows} columns={columns} pageSize={15} rowsPerPageOptions={[5]} />}
    </div>
  );
}

export interface Razpolozljivost {
  id?: string;
  from?: Timestamp;
  to?: Timestamp;
}
