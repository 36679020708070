import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { useCollection } from "react-firebase-hooks/firestore";
import { addDoc, collection, deleteDoc, doc, getDoc, getFirestore, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import firebaseClient from "../../services/firebaseClient";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteUser, getAuth } from "firebase/auth";
import { tableTranslations } from "../../misc/localizations";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

const auth = getAuth(firebaseClient);

export default function PorocilaVozilPage() {
  // auth check
  const navigate = useNavigate();
  const [user, userLoading, userError] = useAuthState(auth);
  if (!user) navigate("/prijava");

  const [users] = useCollection(collection(getFirestore(firebaseClient), "profiles"));

  const [value, loading, error] = useCollection(collection(getFirestore(firebaseClient), "vehicle_log"));
  const [rows, setRows] = useState<Porocilo[]>([]);
  const [editingObject, setEditingObject] = useState<Porocilo | null>(null);

  useEffect(() => {
    setRowData();
  }, [value]);

  const setRowData = async () => {
    if (value == null || value == undefined || error) return;

    let tmpRows: Porocilo[] = [];

    for (let i = 0; i < value!.docs.length; i++) {
      const document = value!.docs[i];
      const data = document.data();

      const vehicle = doc(getFirestore(firebaseClient), "vehicle", data.vehicle);
      const docData = await getDoc(vehicle);
      const vehicleData = docData.data();

      let user;
			users?.docs.map((docUser) => {
				const dataUser = docUser.data();
				if (dataUser.phone_number == data.user) {
					user = dataUser;
				}
			});

      tmpRows.push({
        id: document.id,
        vehicle: vehicleData?.brand ?? "/",
        user: `${user ? user!.name : ''} (${data.user})`,
        location: data.location,
        mileage: data.mileage,
        cost: data.cost,
        notes: data.notes,
        timestamp: data.timestamp,
      } as Porocilo);
    }
    setRows(tmpRows);
  };

  const columns: GridColDef[] = [
    { field: "vehicle", headerName: "Vozilo", minWidth: 150, flex: 1 },
    { field: "user", headerName: "Uporabnik", minWidth: 250, flex: 1 },
    { field: "location", headerName: "Lokacija", minWidth: 150, flex: 1 },
    { field: "mileage", headerName: "Prevoženi km", minWidth: 100, flex: 1 },
    { field: "cost", headerName: "Znesek", minWidth: 80, flex: 1 },
    { field: "notes", headerName: "Opombe", minWidth: 150, flex: 1, renderCell: (params: any) => {
      return <Tooltip title={params?.value}><p>{params?.value}</p></Tooltip>;
    }, },
    {
      field: "timestamp",
      headerName: "Čas",
      minWidth: 160,
      flex: 1,
      renderCell: (params: any) => {
        return <p>{params?.value?.toDate()?.toLocaleString() ?? "/"}</p>;
      },
    },
    {
      field: "options",
      headerName: "Možnosti",
      maxWidth: 90,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton aria-label="delete" size="small" onClick={() => handleDeleteClick(params.id)}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </>
        );
      },
    },
  ];

  const handleDeleteClick = async (id: string) => {
    const shouldDelete = window.confirm("Ali ste prepričani, da želite izbrisati porocilo?");

    if (shouldDelete) {
      const res = await deleteDoc(doc(getFirestore(firebaseClient), "vehicle_log", id));
    }
  };

  return (
    <div className="h-[80vh] w-[1400px] max-w-full p-4 m-auto">
      <h1 className="mb-6 text-3xl">Poročila vozil</h1>
      {rows && <DataGrid localeText={tableTranslations} rows={rows} columns={columns} pageSize={15} rowsPerPageOptions={[5]} sortModel={[{'field': 'timestamp','sort': 'desc',}]} />}
    </div>
  );
}

export interface Porocilo {
  id?: string;
  cost?: number;
  location?: string;
  mileage?: string;
  notes?: string;
  timestamp?: Timestamp;
  user?: string;
  vehicle?: string;
}
