import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { collection, getFirestore } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { Uporabnik } from ".";
import firebaseClient from "../../services/firebaseClient";

interface Props {
  mode: "add" | "edit";
  onSave: ({}: any) => void;
  editingUser?: Uporabnik | null;
  categories: any;
}

export default function AddEditComponent(props: Props) {
  const [user, setUser] = useState({} as Uporabnik);

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const tmpUser = props.editingUser ?? ({} as Uporabnik);

    setUser(tmpUser);
    window.scrollTo(0, 0);
  }, [props.editingUser]);

  const handleSave = (e: any) => {
    e.preventDefault();
    props.onSave(user);
  };

  const handleCancel = () => {
    props.onSave(null);
  };

  return (
    <div className="mt-4 mb-8 p-4 shadow-md bg-gray-50 border-gray-200 border">
      <form onSubmit={handleSave} ref={formRef}>
        <h2>{props.mode == "add" ? "Dodaj" : "Uredi"} uporabnika</h2>
        <div className="form flex flex-col gap-4 my-4">
          <TextField
            required={true}
            label="Ime in priimek"
            aria-valuemin={2}
            type="text"
            value={user.name ?? ""}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
          />
          <TextField
            required={true}
            label="Email"
            type="email"
            value={user.email ?? ""}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
          <TextField
            required={true}
            label="Geslo"
            type="password"
            aria-valuemin={5}
            value={props.mode == "edit" ? "********" : user.password ?? ""}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          />
          <TextField
            required={true}
            label="Telefonska številka"
            type="text"
            value={user.phone_number?.replaceAll('+386', '') ?? ""}
            onChange={(e) => setUser({ ...user, phone_number: e.target.value })}
            InputProps={{
              startAdornment: <InputAdornment position="start">+386</InputAdornment>
            }}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Kategorija</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={user.category ?? ""}
              label="Kategorija"
              onChange={(e) => setUser({ ...user, category: e.target.value.toString() })}
            >
              {props.categories && props.categories!.map((category: any) => (
                <MenuItem value={category}>{category}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={user.active ?? false}
                onChange={(e) => setUser({ ...user, active: e.target.checked })}
              />
            }
            label="Aktiven"
          />
        </div>

        <div className="flex flex-row gap-4">
          <Button variant="contained" type="submit">
            Shrani
          </Button>
          <Button variant="contained" onClick={handleCancel}>
            Prekliči
          </Button>
        </div>
      </form>
    </div>
  );
}
