import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Uporabnik } from "../UporabnikiPage";

interface Props {
  users: Uporabnik[];
  closeDialog: () => void;
}

export default function ConfirmedUsersDialog(props: Props) {
  return (
    <div>
      <DialogTitle>Potrjen kader</DialogTitle>

      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ime</TableCell>
                <TableCell>Kategorija</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.users.length > 0 && props.users.map((user) => (
                user && (
                  <TableRow key={user.id}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.category}</TableCell>
                  </TableRow>
                )
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.closeDialog}>Zapri</Button>
      </DialogActions>
    </div>
  );
}